import styled from '@emotion/styled';
import { ifProp } from 'styled-tools';
import { breakpoints } from '$theme';

export const StyledCarouselButton = styled.button<{ withShadow: boolean }>(
    ({ theme }) => ({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: theme.colors.white,
        color: theme.colors.black,
        width: 50,
        height: 50,
        opacity: 1,
        borderRadius: '100%',
        border: 'none',
        zIndex: 1000,
        cursor: 'pointer',
        transition: `${theme.animations.timingShort} ${theme.animations.easeOutCubic}`,
        transitionProperty: 'opacity, background-color',
        [breakpoints.xs]: {
            WebkitTapHighlightColor: 'transparent',
        },
        [breakpoints.sm]: {
            '&:hover': {
                backgroundColor: theme.colors.red100,
                figure: {
                    color: theme.colors.white100,
                },
            },
        },
    }),
    ifProp('withShadow', ({ theme }) => ({
        boxShadow: theme.elevations.smDarker,
    })),
);
