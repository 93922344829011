import styled from '@emotion/styled';
import { breakpoints } from '~/theme';
import { ifProp } from 'styled-tools';

export const StyledCarouselButtonContainer = styled.span<{ alignment: 'left' | 'right' }>(
    ({ theme }) => ({
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        zIndex: theme.zIndexes[1],
        [breakpoints.sm]: {
            transition: `opacity ${theme.animations.timingShort} ease-out`,
            opacity: 0,
        },
    }),
    ifProp(
        { alignment: 'left' },
        {
            left: 10,
            [breakpoints.lg]: {
                left: 20,
            },
        },
        {
            right: 10,
            [breakpoints.lg]: {
                right: 20,
            },
        },
    ),
);

export const StyledProductCarouselWrapper = styled.div(({ theme }) => ({
    position: 'relative',
    paddingBottom: theme.spaces[8],
    [`&:hover ${StyledCarouselButtonContainer}`]: {
        opacity: 1,
    },
    '.swiper': {
        overflow: 'visible',
    },
    '.swiper-slide': {
        height: 'auto',
    },
    '.productCard': {
        height: '100%',
    },
}));

export const StyledRelativeContainer = styled.div(() => ({
    position: 'relative',
}));
