import styled from '@emotion/styled';
import { breakpoints } from '~/theme';
import { ifProp } from 'styled-tools';

export const StyledLoadMoreWrapper = styled.div(({ theme }) => ({
    width: '100%',
    display: 'flex',
    margin: `${theme.spaces[7]} 0 0`,
    justifyContent: 'center',
    [breakpoints.md]: {
        margin: `${theme.spaces[9]} 0 0`,
    },
}));

export const StyledProductCollectionContainer = styled.div<{ isCarousel?: boolean }>(
    ifProp('isCarousel', {
        overflow: 'hidden',
    }),
);
