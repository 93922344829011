import { useMemo } from 'react';
import { KProductCardType } from '~/lib/data-contract';
import { Gutter, MaxWidth } from '~/shared/components';
import { KProductCard } from '~/shared/components/KProductCard';
import { Frame, useFrame, useTranslation } from '~/shared/utils';
import { mapProductToBasicProps } from '~/templates/blocks/components/M140ProductsList/components/utils/mapProductToBasicProps';
import { getCurrentMarketPath } from '~/templates/pages/utils';
import { Product } from '../../../../../templates/blocks/components/M140ProductsList';
import { StyledProductGridItem, StyledProductsGrid } from './styled';

export interface ThreeColumnProductListProps {
    products: Product[];
}

export const ThreeColumnProductList = ({ products = [] }: ThreeColumnProductListProps) => {
    const productPageUrl = `/${getCurrentMarketPath()}/p`;
    const { data: frame } = useFrame();
    const { translate } = useTranslation();

    const productsAsProps = useMemo(() => {
        return products.map((item) =>
            mapProductToBasicProps({
                product: item,
                productPageUrl,
                frame: frame as Frame,
                translate,
            }),
        );
    }, [products, productPageUrl, frame, translate]);

    return (
        <MaxWidth>
            <Gutter>
                <StyledProductsGrid>
                    {productsAsProps.map((item) => (
                        <StyledProductGridItem key={`${item.productId ?? item.title}`}>
                            <KProductCard type={KProductCardType.REGULAR} {...item} />
                        </StyledProductGridItem>
                    ))}
                </StyledProductsGrid>
            </Gutter>
        </MaxWidth>
    );
};
