import { StyledCarouselButton } from './styled';
import ArrowLeft from '$icons/arrow-left.svg';
import ArrowRight from '$icons/arrow-right.svg';
import { Icon } from '~/shared/components/Icon';
import React, { forwardRef } from 'react';

type CarouselButtonProps = {
    direction: 'left' | 'right';
    withShadow?: boolean;
    onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
};

export const CarouselButton = forwardRef<HTMLButtonElement, CarouselButtonProps>(
    ({ onClick, direction, withShadow = true }: CarouselButtonProps, ref) => {
        return (
            <StyledCarouselButton
                type="button"
                withShadow={withShadow}
                ref={ref}
                onClick={(e) => {
                    e.preventDefault();
                    onClick && onClick(e);
                }}
            >
                <Icon size="sm">
                    {direction == 'left' && <ArrowLeft />}
                    {direction == 'right' && <ArrowRight />}
                </Icon>
            </StyledCarouselButton>
        );
    }
);
