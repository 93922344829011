import { useEffect, useState } from 'react';
import SwiperCore from 'swiper';
import { SwiperEvents } from 'swiper/types/swiper-events';

type useSwiperHelperCallbacks = {
    [event in keyof SwiperEvents]?: SwiperEvents[event];
};

export const useSwiperHelper = (
    swiperInstance?: SwiperCore,
    callbacks: useSwiperHelperCallbacks = {}
) => {
    const [hasNext, setHasNext] = useState(true);
    const [hasPrev, setHasPrev] = useState(false);

    const internalSlideChangeCallback = (swiper: SwiperCore) => {
        setHasNext(!swiper.isEnd);
        setHasPrev(!swiper.isBeginning);
    };

    useEffect(() => {
        if (!swiperInstance) return;

        // internal tracker for state changes
        swiperInstance.on('transitionEnd', internalSlideChangeCallback);
        internalSlideChangeCallback(swiperInstance);

        // Loop all callbacks and apply to swiper
        if (callbacks) {
            Object.entries(callbacks).forEach(([key, callback]) => {
                swiperInstance.on(key as keyof SwiperEvents, callback);
            });
        }

        return () => {
            // Loop all callbacks and unregister
            if (callbacks) {
                Object.entries(callbacks).forEach(([key, callback]) => {
                    swiperInstance.off(key as keyof SwiperEvents, callback);
                });
            }
        };
    }, [swiperInstance]);

    const slideNext = () => {
        if (!swiperInstance) return;
        swiperInstance.slideNext();
    };
    const slidePrev = () => {
        if (!swiperInstance) return;
        const { slidesPerView, slidesPerGroup, slidesPerGroupAuto } = swiperInstance.params;
        // Swiper has a bug where prevSlide will go 1 slide too far when using slidesPerGroupAuto.
        if (slidesPerView == 'auto' && slidesPerGroup == 1 && slidesPerGroupAuto == true) {
            const currentIndex = swiperInstance?.activeIndex;
            // eslint-disable-next-line  @typescript-eslint/no-explicit-any
            const slidesToScroll = (swiperInstance as any)?.slidesPerViewDynamic('current', true);
            if (slidesToScroll && currentIndex) {
                swiperInstance.slideTo(currentIndex - slidesToScroll);
                return;
            }
        }
        swiperInstance.slidePrev();
    };

    return {
        slideNext,
        slidePrev,
        swiperInstance,
        hasNext,
        hasPrev,
    };
};
