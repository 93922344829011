import { useRawHtml } from './hooks';
import { StyledRichText } from './styled';

export type RawHtmlProps = React.HTMLAttributes<HTMLDivElement> & {
    html?: string;
    removeLastChildMargin?: boolean;
};

export const RawHtml = ({ html = '', removeLastChildMargin, ...rest }: RawHtmlProps) => {
    const markup = useRawHtml(html);

    if (html === '<p></p>') return null;

    return (
        <StyledRichText removeLastChildMargin={removeLastChildMargin} {...rest}>
            {markup}
        </StyledRichText>
    );
};
