import styled from '@emotion/styled';
import { breakpoints } from '~/theme';

export const StyledProductsGrid = styled.ul(({ theme }) => ({
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gap: `${theme.spaces[4]} ${theme.spaces[3]}`,
    [breakpoints.sm]: {
        gap: `${theme.spaces[6]} ${theme.spaces[5]}`,
    },
    [breakpoints.md]: {
        gridTemplateColumns: 'repeat(3, 1fr)',
        gap: `${theme.spaces[8]} ${theme.spaces[7]}`,
    },
    [breakpoints.lg]: {
        gridTemplateColumns: 'repeat(3, 1fr)',
    },
}));

export const StyledProductGridItem = styled.li(({ theme }) => ({
    position: 'relative',
    [breakpoints.md]: {
        [':nth-of-type(3n+2)']: {
            top: theme.spaces[7],
        },
    },
}));
