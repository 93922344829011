import { Button, Gutter, Icon, MaxWidth, ModuleHeader } from '$shared/components';
import React, { memo } from 'react';
import { Link, ProductCarouselModuleLayoutEnum } from '~/lib/data-contract';
import ArrowRight from '$icons/arrow-right.svg';
import { useTranslation } from '~/shared/utils/translation';
import { ThreeColumnProductList } from './components/ThreeColumnProductsList/ThreeColumnProductList';
import { ProductCarousel } from './components/ProductCarousel/ProductCarousel';
import { StyledLoadMoreWrapper, StyledProductCollectionContainer } from './styled';
import { Product } from '~/templates/blocks/components/M140ProductsList';

const ContentLayoutComponent = memo(
    ({
        products = [],
        contentLayout,
    }: {
        products: Product[];
        contentLayout: ProductCarouselModuleLayoutEnum;
    }) => {
        switch (contentLayout) {
            case ProductCarouselModuleLayoutEnum.THREE_COLUMN:
                return <ThreeColumnProductList products={products} />;
            case ProductCarouselModuleLayoutEnum.CAROUSEL:
                return <ProductCarousel products={products} />;
            default:
                console.error(`${contentLayout} not implemented`);
                return null;
        }
    },
);

type ProductCollectionLayoutProps = {
    contentLayout?: ProductCarouselModuleLayoutEnum;
    showLoadMore: boolean;
    onLoadMoreClick: () => void;
    callToAction?: Link;
    headline?: string;
    subheadline?: string;
    products: Product[];
};

export const ProductCollectionLayout = ({
    headline,
    subheadline,
    callToAction,
    contentLayout = ProductCarouselModuleLayoutEnum.THREE_COLUMN,
    showLoadMore,
    onLoadMoreClick,
    products,
}: ProductCollectionLayoutProps) => {
    const { translate } = useTranslation();

    const isCarousel = contentLayout === ProductCarouselModuleLayoutEnum.CAROUSEL;
    const hasProducts = products.length > 0;

    return (
        <StyledProductCollectionContainer isCarousel={isCarousel}>
            <MaxWidth>
                <Gutter>
                    <ModuleHeader
                        headline={headline}
                        description={subheadline}
                        callToAction={callToAction}
                    />
                </Gutter>
            </MaxWidth>
            {hasProducts && (
                <ContentLayoutComponent products={products} contentLayout={contentLayout} />
            )}

            {showLoadMore && onLoadMoreClick && !isCarousel && (
                <StyledLoadMoreWrapper>
                    <Button
                        variant="Transparent"
                        disableHoverAnimation={true}
                        description="Load more"
                        icon={
                            <Icon size="sm">
                                <ArrowRight />
                            </Icon>
                        }
                        onClick={() => onLoadMoreClick()}
                    >
                        {translate('Kompan.RelevantContent.ButtonLabelLoadMore')}
                    </Button>
                </StyledLoadMoreWrapper>
            )}
        </StyledProductCollectionContainer>
    );
};
