import React from 'react';
import { Link } from '~/lib/data-contract';
import { CallToAction, TextVariants } from '~/shared/components';
import NextLink from 'next/link';
import {
    StyledHeader,
    StyledHeaderContent,
    StyledHeaderAction,
    StyledHeaderHeadline,
    StyledHeaderRawHtml,
} from './styled';

export interface AlignContentProps {
    headline?: string;
    description?: string;
    callToAction?: Link;
    variant?: TextVariants;
}

export const ModuleHeader = ({
    headline,
    description,
    callToAction,
    variant = 'display3',
}: AlignContentProps) => {
    return (
        <StyledHeader>
            <StyledHeaderContent>
                <StyledHeaderHeadline
                    children={headline}
                    variant={variant}
                    description={description}
                />
                {description && <StyledHeaderRawHtml html={description} />}
            </StyledHeaderContent>
            {callToAction && callToAction.url && (
                <StyledHeaderAction>
                    <NextLink href={callToAction.url} passHref legacyBehavior>
                        <CallToAction callToAction={callToAction} />
                    </NextLink>
                </StyledHeaderAction>
            )}
        </StyledHeader>
    );
};
