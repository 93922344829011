/* eslint @typescript-eslint/no-empty-interface: "off" */
/* eslint @typescript-eslint/ban-types: "off" */
import { QueryClient } from 'react-query';

import type { ButtonShade, ButtonVariant, ImageProps } from '~/shared/components';
import {
    ProductCategoryBaseItem,
    ProductSearchRequestResponseType,
} from '~/templates/blocks/components/M140ProductsList';

import { ContactFormInputs } from '~/templates/blocks/components/M40ContactForm/M40ContactForm';
import { GetServerSidePropsContext } from 'next/types';
import { FormNameType } from '~/shared/utils/forms';

import { NewsletterFormInputs } from '~/templates/blocks/components/M41NewsletterForm/M41NewsletterForm';
export interface AccordionItem {
    description: RichText;
    headline: string;
    callToAction?: Link;
}

export type Basket = Globalizable & {
    id: string;
    lineItems: LineItem[];
    quantitySum: number;
    totals: Totals;
};

export type BreadcrumbItem = {
    id: string;
    target: string;
    text: string;
    title: string;
    url: string;
};

export interface Breadcrumb {
    items?: BreadcrumbItem[];
}

export interface Category {
    alias?: 'category';
    callToAction?: Link;
    image?: ImageMedia;
    title?: string;
}

export interface Currency {
    id?: string;
    symbol?: string;
}

export interface StructuredDataOrganizationModel {
    organizationType?: string;
    organizationName?: string;
    organizationAlternateName?: string;
    organizationUrl?: string;
    organizationLogo?: string;
    organizationSameAsItems?: string[];
}

export interface Footer {
    additionalIcons?: FooterIcon[];
    bottomLinks?: Link[];
    contactTitle?: string;
    contactText?: RichText;
    addressTitle?: string;
    addressText?: RichText;
    id?: string;
    logoImage?: ImageMedia;
    menu?: NavigationNode[];
    newsletterLink?: Link;
    newsletterText?: RichText;
    paymentIcons?: FooterIcon[];
    socialIcons?: SocialIcon[];
    copyrights?: string;
}

export interface FooterIcon {
    image: ImageMedia;
    title?: string;
}

export interface SocialIcon {
    image?: ImageMedia;
    link?: Link;
}

export interface Frame {
    culture?: string;
    footer?: Footer;
    header?: Header;
    id?: string;
    market?: Market;
    marketSelector?: MarketSelector;
    marketName?: string;
    navigation?: Navigation;
    search?: Search;
    usp?: Usp;
    settings?: SiteSettings;
    staticLinks?: StaticLinks;
    type?: string;
    newsletterLink?: Link;
    searchShortCuts?: {
        searchShortCuts?: Link[];
    };
    currency?: string;
    currencySymbol?: string;
    structuredDataOrganization?: StructuredDataOrganizationModel;
}

export type Globalizable = Localizable & { market: string };

export interface Header {
    id?: string;
}

export type HeaderTag = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'p';

export type Headline = string;

export type HorizontalAlignment = 'left' | 'right';

export type KompanHorizontalAlignment = 'left' | 'center';

export type HrefLangLink = { url: string; xDefault?: boolean };

export type ImageMedia = Media & {
    alt?: string;
    imageFocalPoint?: {
        x: number;
        y: number;
    };
};

export interface LineItem {
    discountedLinePrice?: Money;
    discountedUnitPrice?: Money;
    id?: string;
    linePrice: Money;

    /** @format double */
    quantity: number;
    unitPrice: Money;
    variation: Variation;
}

export type SidepanelModule =
    | M40ContactFormModule
    | M41NewsletterFormModule
    | (M100RichTextModule & {
          type: string;
      });

export interface Link {
    id?: string;
    target?: string;
    text?: string;
    title?: string;
    url: string;
    linkReference?: Module;
    linkIcon?: ImageMedia;
    variation?: ButtonVariant;
    iconAlignment?: 'start' | 'end';
    shade?: ButtonShade;
    iconName?: string;
    sidepanelModule?: M40ContactFormModule | M41NewsletterFormModule | M100RichTextModule;
}

export interface Localizable {
    culture: string;
}

export type M100RichTextModule = Module & {
    headline?: Headline;
    text?: RichText;
    type: 'm100RichTextModule';
    truncated?: boolean;
    callToAction?: Link;
    legalDisclaimer?: RichText;
};

export type M10HeroModule = Module & {
    headline?: Headline;
    subHeadline?: Headline;
    headlinePosition?: 'default' | 'aboveMedia';
    callToAction?: Link;
    secondaryCallToAction?: Link;
    horizontalAlignment?: KompanHorizontalAlignment;
    image?: ImageMedia;
    mediaLayout: ModuleMediaLayout;
    type: 'm10HeroModule';
    video?: VideoMedia;
};

export type M110TenderTextModule = Module & {
    headline?: Headline;
    subHeadline?: Headline;
    image?: ImageMedia;
    text?: RichText;
    type: 'm110TenderTextModule';
};

export type M120AccordionListModule = Module & {
    accordionItems: AccordionItem[];
    headline?: Headline;
    type: 'm120AccordionListModule';
};

export type ProductSpecificationsModule = {
    accordionItems: AccordionItem[];
    headline?: Headline;
    description?: string;
};

export type ProductCarouselModulePagesize = '3' | '6' | '9';

export enum ProductCarouselModuleLayoutEnum {
    CAROUSEL = 'Carousel',
    THREE_COLUMN = 'Three-Column',
}

export type M30ProductCarouselProductItem = {
    productId: string;
    displayName: string;
};

export type M30ProductCarouselModule = Module & {
    type: 'm30ProductCarouselModule';
    headline?: string;
    callToAction?: Link;
    subheadline?: string;
    selectedProducts?: M30ProductCarouselProductItem[];
    contentLayout?: ProductCarouselModuleLayoutEnum;
    pageSize?: ProductCarouselModulePagesize;
};

export type M31PopularProductsModule = Module & {
    type: 'm31PopularProductsModule';
    headline?: string;
    callToAction?: Link;
    subheadline?: string;
    productCategories?: string[];
    contentLayout?: ProductCarouselModuleLayoutEnum;
    pageSize?: ProductCarouselModulePagesize;
};

export type M32SimilarProductsModule = Module & {
    type: 'm32SimilarProductsModule';
    headline?: string;
    callToAction?: Link;
    subheadline?: string;
    contentLayout?: ProductCarouselModuleLayoutEnum;
    pageSize?: ProductCarouselModulePagesize;
};

export type M33SimilarStyleProductsModule = Module & {
    type: 'm33SimilarStyleProductsModule';
    headline?: string;
    callToAction?: Link;
    subheadline?: string;
    contentLayout?: ProductCarouselModuleLayoutEnum;
    pageSize?: ProductCarouselModulePagesize;
};

export type M34SimilarProductsToLastProductViewedModule = Module & {
    type: 'm34SimilarProductsToLastProductViewedModule';
    headline?: string;
    callToAction?: Link;
    subheadline?: string;
    contentLayout?: ProductCarouselModuleLayoutEnum;
    pageSize?: ProductCarouselModulePagesize;
};

export enum FormConversionSource {
    M40ContactForm = 'General Contact Form',
    M41NewsletterForm = 'Newsletter Form',
    M42CatalogueForm = 'Catalogue Form',
    M43WebinarForm = 'Webinar Form',
    ProductGetQuote = 'Quote Request Form',
    MenuGetQuote = 'Menu Form',
    ConfiguratorGetQuote = 'Product Designer Form',
    M44KCSContactForm = 'KCS contact form',
    TenderTextForm = 'Tender Text Form',
    RecommendationFlowForm = 'Recommendation flow Form',
}

export type M40ContactFormModule = Module & {
    type: 'm40ContactFormModule';
    sourceCampaignCode?: string;
    topic?: string;
    leadDescription?: string;
    description?: string;
    headline?: string;
    receiptDescription?: string;
    receiptHeadline?: string;
    receiptCallToAction?: Link;
    disclaimerShortText?: string;
    legalDisclaimer?: string;
    conversionSource?: FormConversionSource;
    subscribeNewsletterRequired?: boolean;

    FormNameTypeEndpoint?: FormNameType;
    /**
     * Skip showing receipt panel
     */
    skipReceipt?: boolean;
    /**
     * Callback for successful contact form submission
     */
    onFormSubmit?: (data: ContactFormInputs) => void;
    /**
     * Contact form for errors during submission
     */
    onFormError?: () => void;
    /**
     * Callback on closing sidepanel
     */
    onPanelClose?: () => void;
    /**
     * Disabled normal tracking in case you need custom tracking event
     */
    disableTracking?: boolean;
    /**
     * Option to inject data to the message field on submission.
     */
    injectMessage?: string | null;
    /**
     * To add a personalized tracking event
     */
    trackingEvent?: (data: ContactFormInputs) => void;
    /*
     * initial Text/message for textArea Description
     */
    descriptionMessage?: string;
};

export type M44GeneralKCSFormModule = Module & {
    type: 'm44GeneralKCSFormModule';
    sourceCampaignCode?: string;
    topic?: string;
    leadDescription?: string;
    description?: string;
    headline?: string;
    receiptDescription?: string;
    receiptHeadline?: string;
    receiptCallToAction?: Link;
    disclaimerShortText?: string;
    legalDisclaimer?: string;
    conversionSource?: FormConversionSource;
    subscribeNewsletterRequired: boolean;
    /**
     * Skip showing receipt panel
     */
    skipReceipt?: boolean;
    /**
     * Callback for successful contact form submission
     */
    onFormSubmit?: (data: ContactFormInputs) => void;
    /**
     * Contact form for errors during submission
     */
    onFormError?: () => void;
    /**
     * Callback on closing sidepanel
     */
    onPanelClose?: () => void;
    /**
     * Disabled normal tracking in case you need custom tracking event
     */
    disableTracking?: boolean;
    /**
     * Option to inject data to the message field on submission.
     */
    injectMessage?: string | null;
    /**
     * To add a personalized tracking event
     */
    trackingEvent?: (data: ContactFormInputs) => void;
};

export type M41NewsletterFormModule = Module & {
    type: 'm41NewsletterFormModule';
    sourceCampaignCode?: string;
    topic?: string;
    leadDescription?: string;
    description?: string;
    headline?: string;
    disclaimerShortText?: string;
    legalDisclaimer?: string;
    receiptDescription?: string;
    receiptHeadline?: string;
    receiptCallToAction?: Link;
    conversionSource?: FormConversionSource;
    /**
     * Disabled normal tracking in case you need custom tracking event
     */
    disableTracking?: boolean;
    /**
     * To add a personalized tracking event
     */
    trackingEvent?: (data: NewsletterFormInputs) => void;
    /**
     * Callback on closing sidepanel
     */
    onPanelClose?: () => void;
};

export type CatalogueItem = {
    title: string;
    image: MediaItem;
    id: string;
    type: string;
};

export type M42CatalogueFormModule = Module & {
    type: 'm42CatalogueFormModule';
    sourceCompaign?: string; // CMS has typo in field
    topic?: string;
    leadDescription?: string;
    description?: string;
    headline?: string;
    disclaimerShortText?: string;
    legalDisclaimer?: string;
    receiptDescription?: string;
    receiptHeadline?: string;
    receiptCallToAction?: Link;
    catalogues: CatalogueItem[];
    conversionSource?: FormConversionSource;
};

export type M43WebinarFormModule = Module & {
    type: 'm43WebinarFormModule';
    sourceCampaignCode?: string;
    topic?: string;
    leadDescription?: string;
    description?: string;
    headline?: string;
    disclaimerShortText?: string;
    legalDisclaimer?: string;
    receiptDescription?: string;
    receiptHeadline?: string;
    receiptCallToAction?: Link;
    webinarUrl?: string;
    webinarDate?: string;
    conversionSource?: FormConversionSource;
};

export type M60UspModule = Module & { columns?: 2 | 3; items: UspItem[]; type: 'm60UspModule' };

export type MediaItem = {
    id: string;
    type: string;
    extension?: string;
    height: number;
    name: string;
    ratio?: number;
    src: string;
    width: number;
    alt?: string;
    isDamImage?: boolean;
    imageFocalPoint?: {
        x: number;
        y: number;
    };
};

export type M70MediaItem = {
    id: string;
    caption: string;
    image: MediaItem;
    imageFocalPoint?: {
        focalPoint: {
            x: number;
            y: number;
        };
    };
    externalVideoSrc?: string;
};

export type M70MediaModule = Module & {
    type: 'm70MediaModule';
    headline?: string;
    mediaItems?: M70MediaItem[];
    columns?: number;
    height?: 'small' | 'large';
    isCarousel?: boolean;
};

export type M75MediaAndTextModule = Module & {
    headline?: Headline;
    subHeadline?: Headline;
    horizontalAlignment?: KompanHorizontalAlignment;
    mediaHorizontalAlignment: HorizontalAlignment;
    callToAction?: Link;
    secondaryCallToAction?: Link;
    image?: ImageMedia;
    text?: RichText;
    video?: VideoMedia;
    type: 'm75MediaAndTextModule';
};

export type M80MediaAndTextBundleItem = MediaAndTextItem | PageReference;

export type M80MediaAndTextBundleModule = Module & {
    callToAction?: Link;
    headline?: Headline;
    description?: string;
    mediaAndTextItems: M80MediaAndTextBundleItem[];
    type: 'm80MediaAndTextBundleModule';
    isCarousel?: boolean;
    columns?: number;
    loadMore?: boolean;
};

export type M85DetailsItem = {
    links?: Link[];
    priceRate?: number;
    title?: string;
    text?: string;
};

export type M85DetailsModule = Module & {
    headline?: string;
    type: 'm85DetailsModule';
    items: M85DetailsItem[];
};

export type M86HighlightListItem = {
    title?: string;
    description?: string;
    richTextDescription?: RichText;
    callToAction?: Link;
    image?: MediaItem;
};

export type M86HighlightListModule = Module & {
    callToAction?: Link;
    headline?: Headline;
    items: M86HighlightListItem[];
    type: 'm86HighlightListModule';
};

export type RelevantContentItem = {
    headline?: string;
    callToAction?: string;
    image?: MediaItem;
    tags: string;
    updatedDate?: string;
    contentType?: RelevantContentTypeEnum;
};

export type RelevantContentResponse = {
    relevantContent: RelevantContentItem[];
    currentIndex: number;
    totalNumberOfPages: number;
    pageSize: number;
};

export enum RelevantContentTypeEnum {
    WEBINARS = 'relevantContentWebinars',
    NEWS = 'relevantContentNews',
    ARTICLES = 'relevantContentArticles',
}

export type PageTag = {
    [key: string]: string;
};

export type PageTags = {
    title: string;
    tagsList?: PageTag[];
};

export type RelewiseResponsePageTags = {
    title: string;
    tagsList?: string[];
};

export enum PageSizeTypeEnum {
    SNIPPET = 'Snippet',
    LONG_LIST = 'Long list',
}

export enum ContentSortingEnum {
    ALPHABETICAL_ASC = 'Alphabetical ASC',
    ALPHABETICAL_DESC = 'Alphabetical DESC',
    CREATED_DATE_ASC = 'Created date ASC',
    CREATED_DATE_DESC = 'Created date DESC',
    PUBLISH_DATE_ASC = 'Publish date ASC',
    PUBLISH_DATE_DESC = 'Publish date DESC',
    POPULARITY = 'Popularity',
    RELEVANCE = 'Relevance',
}

export enum M89RelevantContentLayoutEnum {
    THREE_COLUMN = 'Three-Column',
    ONE_COLUMN = 'One-Column',
    CAROUSEL = 'Carousel',
    ASYMMETRICAL_GIRD = 'Asymmetrical grid',
}

export type M89RelevantContentModule = Module & {
    type: 'm89RelevantContentModule';
    callToAction?: Link;
    contentLayout?: M89RelevantContentLayoutEnum;
    headline?: string;
    subHeadline?: string;
    loadmore: boolean;
    pageSize?: PageSizeTypeEnum;
    relevantTags: string;
    // New filtering settings
    showUserFilters?: boolean;
    showCategoryFilters?: boolean;
    showSorting?: boolean;
    defaultSort?: ContentSortingEnum;
};

export type M90QuoteModule = Module & {
    author?: string;
    quote?: string;
    type: 'm90QuoteModule';
    callToAction?: Link;
    horizontalAlignment?: KompanHorizontalAlignment;
};

export type ProductDescriptionModule = {
    description: string;
    productSpecificationsAnchor: string;
};

export type ProductBreadcrumbModule = {
    breadcrumb?: Breadcrumb;
    title?: string | null;
};

export type M130ProductDetailModule = Module & {
    type: 'm130ProductDetailModule';
    uspList?: UspItem[];
};

export type M140ProductListContentLayout = 'List' | 'Cards';
export type M140ProductListModule = Module & {
    type: 'm140ProductListModule';
    headline?: string;
    showUserFilters: boolean;
    showCategoryOverview: boolean;
    showEmptyCategories?: boolean;
    callToAction?: Link;
    productsPerPage: string;
    /**
     * @deprecated
     * use productCategoryIds field instead
     */
    productListConfig: {
        selectedCategory: ProductCategoryBaseItem | null;
    };
    productCategoryIds?: string[];
    paginationType: 'Continuous Loading' | 'Single Page';
    defaultLayout: M140ProductListContentLayout;
    initialState?: ProductSearchRequestResponseType | null;
    inclusiveFilter?: boolean;
    greenlineEditionFilter?: boolean;
    quickSupplyFilter?: boolean;
    playCapacityFromFilter?: number;
    playCapacityToFilter?: number;
    keyUserGroupFromMinFilter?: number;
    keyUserGroupFromMaxFilter?: number;
    keyUserGroupToMinFilter?: number;
    keyUserGroupToMaxFilter?: number;
    webGeneralTagsHeadline?: string;
    webGeneralTagsOptions?: string[];
    showOnlyProductsList?: boolean;
    showPagination?: boolean;
};

export type M150LargeTextModule = Module & {
    type: 'm150LargeText';
    text: string;
};

export type MyKompanDashboardPageReference = {
    callToAction: Link;
    pageReferenceDescription?: string;
    pageReferenceImage: MediaItem;
    url: string;
    type: 'blockMyKompanDashboardPage';
};

export type MyKompanDashboardModule = PageElement & {
    type: 'myKompanDashboardModule';
    headline?: string;
    description?: string;
    dashboardPages?: MyKompanDashboardPageReference[];
};

export type MyKompanProfileModule = PageElement & {
    type: 'myKompanProfileModule';
    headline?: string;
    description?: string;
};

export type MyKompanMySpacesModule = PageElement & {
    type: 'myKompanMySpacesModule';
    headline?: string;
    description?: string;
};

export type MyKompanAcademyModule = PageElement & {
    type: 'myKompanAcademyModule';
    trainingHeadline?: string;
    coursesHeadline?: string;
};

export type StaticErrorPageModule = Module & {
    type: 'staticErrorPageModule';
};

export type Static404PageModule = Module & {
    type: 'static404PageModule';
};

export type ProductHeroQrData = {
    appStoreLink?: string;
    playStoreLink?: string;
    qrCodeInfoText?: string;
    appStoreImage?: ImageMedia;
    playStoreImage?: ImageMedia;
};

export type ProductHeroModule = ProductHeroQrData & {
    productId: string;
    name: string;
    imageSource?: string | null;
};

export type M170JobMatchProfileModule = Module & {
    type: 'm170JobMatchProfileModule';
    title: string;
    description: string;
    iframeSourceLink?: string;
};

export type M180CookiePolicyModule = Module & {
    type: 'm180CookiePolicyModule';
};
export interface ContentfulLocation {
    lat: number;
    lon: number;
    lng?: number;
}
export type BlockMapLocation = {
    id: string;
    type: 'blockMapLocation';
    headline?: string;
    subheadline?: string;
    phone?: string | null;
    email?: string;
    address?: string;
    fax?: string | null;
    callToAction?: Link;
    location: ContentfulLocation;
    locationType?: string; // deprecated
    locationTypeList?: string;
    showDirections?: boolean;
    gallery?: ImageProps[];
};

export type BlockMapInitialLocationUserGeolocation = {
    id: string;
    type: 'blockMapInitialLocationUserGeolocation';
};

export type BlockMapInitialLocationCurrentMarket = {
    id: string;
    type: 'blockMapInitialLocationCurrentMarket';
};

export type BlockMapInitialLocationCustomCoordiates = {
    id: string;
    type: 'blockMapInitialLocationCustomCoordinates';
    coordinates?: string[];
};

export type InitialLocation =
    | BlockMapLocation
    | BlockMapInitialLocationUserGeolocation
    | BlockMapInitialLocationCurrentMarket
    | BlockMapInitialLocationCustomCoordiates;

export type M160MapModule = Module & {
    type: 'm160MapModule';
    headline?: string;
    subheadline?: string;
    locations: BlockMapLocation[];
    initialLocation?: InitialLocation;
    locationType?: string; // deprecated
    locationTypeList?: string;
};
export interface Market {
    market?: string;
    currency?: Currency;
    id: string;
    pricesIncludeTax?: boolean;
}

export interface MarketBanner {
    buttonText?: string;
    dismissButtonText?: string;
    marketLink?: Link;
    selectMarketLabel?: string;
    selectOtherMarketText?: string;
    text?: string;
}

export interface MarketSelector {
    markets?: MarketSelectorMarket[];
}

export interface MarketSelectorLanguage {
    culture?: string;
    id?: string;
    target?: string;
    text?: string;
    title?: string;
    url?: string;
}

export interface MarketSelectorMarket {
    id?: string;
    label?: string;
    languages?: MarketSelectorLanguage[];
    navCompanyCode?: string;
}

export interface Media {
    caption?: string;
    height: number;
    id: string;
    name?: string;

    /** Source of media. */
    src: string;
    type?: string;
    width: number;
    isDamImage?: boolean;
}

export interface MediaAndTextItem {
    type: 'blockContentArticle' | 'blockPageReference';
    callToAction?: Link;
    headline?: string;
    description?: string;
    richTextDescription?: RichText;
    image?: ImageMedia;
    verticalAlignment?: VerticalAlignment;
    video?: VideoMedia;
}

export type Module = ModuleTheme & {
    id: string;
    type: string;
    pageElementIndex: number;
    disableModuleAnimation?: boolean;
    loadOnSidepanel?: boolean;
    anchorPointReference?: string;
};

export type ModuleHeight = 'narrow' | 'normal' | 'tall';

export type ModuleMediaLayout = 'full' | 'contained';

export type Modules =
    | M100RichTextModule
    | M10HeroModule
    | M120AccordionListModule
    | M30ProductCarouselModule
    | M31PopularProductsModule
    | M32SimilarProductsModule
    | M33SimilarStyleProductsModule
    | M34SimilarProductsToLastProductViewedModule
    | M60UspModule
    | M70MediaModule
    | M75MediaAndTextModule
    | M80MediaAndTextBundleModule
    | M90QuoteModule
    | M130ProductDetailModule
    | (M100RichTextModule &
          M10HeroModule &
          M120AccordionListModule &
          M30ProductCarouselModule &
          M31PopularProductsModule &
          M32SimilarProductsModule &
          M33SimilarStyleProductsModule &
          M34SimilarProductsToLastProductViewedModule &
          M60UspModule &
          M70MediaModule &
          M75MediaAndTextModule &
          M80MediaAndTextBundleModule &
          M90QuoteModule &
          M130ProductDetailModule);

export type ModuleSpace = 'default' | 'none' | 'small';

export interface ModuleSpacing {
    spacingBottom?: ModuleSpace;
    spacingTop?: ModuleSpace;
}

export type ModuleTheme = ModuleSpacing & { backgroundColor?: string };

export interface Money {
    /** @format double */
    amount: number;
}

export interface Navigation {
    id?: string;
    mainMenu?: MenuNode[];
    getAQuote?: {
        getAQuoteEnabled?: boolean;
        getAQuoteModule?: M100RichTextModule | M40ContactFormModule | M42CatalogueFormModule;
    };
}

export interface NavigationNode {
    children?: NavigationNode[];
    id?: string;
    link: Link;
    promotedChildren?: PromotedNavigationNode[];
}

export interface MenuNode {
    id?: string;
    link: MenuLink;
    subSection?: MenuSubSection[];
    metaMenuSections?: MetaMenuSection[];
}

export interface MetaMenuSection {
    title?: string;
    pageReference?: MenuLink;
    metaMenuItems?: MetaMenuItem[];
}

export interface MetaMenuItem {
    title?: string;
    url?: MenuLink;
}

export interface MenuSubSection {
    pageReference?: MenuLink;
    image?: ImageMedia;
    title?: string;
    subMenuItems?: MenuSubNode[];
}

export interface MenuSubNode {
    image?: ImageMedia;
    title?: string;
    url: MenuLink;
}

export interface MenuLink {
    title: string;
    text?: string;
    url: string;
}

export type P20FrontPage = Page & { type: 'p20FrontPage' };

export type P140NotFoundPage = Page & { type: 'p140NotFoundPage' };

export type P150ErrorPage = Page & { type: 'p150ErrorPage' };

export type P60ModulePage = Page & { type: 'p60ModulePage' };

export type P70ProductDetailsPageTemplate = Page & {
    type: 'p70ProductDetailsPageTemplate';
    playStoreImage?: MediaItem;
    playStoreLink?: string;
    appStoreImage?: MediaItem;
    appStoreLink?: string;
    qrCodeInfoText?: string;
    sustainabilityHeadline?: string;
    sustainabilitySubheadline?: RichText;
    sustainabilityCallToAction?: Link;
    sustainabilityDisclaimerText?: RichText;
    additionalDescription?: string;
    playValuesHeadline?: string;
    playValuesDescription?: RichText;
    playValuesCallToAction?: Link;
};

export type P80SearchResultsPage = Page & {
    type: 'p80SearchResultsPage';
    noResultsPageElements?: PageElement[];
    initialState?: ProductSearchRequestResponseType | null;
};

export type P90FavoritePageTemplate = Page & {
    type: 'p90FavoritePageTemplate';
};

type productActivitiesItem = {
    Alias: string;
    Count: number;
};

export type productContentfulData = {
    [key: string]: {
        activities: productActivitiesItem[];
        segment: string[];
    };
};

export type P100RecommendationsFlowPageTemplate = Page & {
    subheadline?: string;
    productActivities?: productContentfulData;
    videoReference?: VideoMedia;
    type: 'p100RecommendationsFlows';
};

export type P410StaticNotFoundPage = Page & {
    type: 'p410StaticNotFoundPage';
};

export type P401UnauthorizedPage = Page & {
    type: 'p401UnauthorizedPage';
};

export type Page = Globalizable & {
    breadcrumb?: Breadcrumb;
    hrefLangs?: HrefLangLink[];
    id: string;
    globalSeoSettings?: {
        noIndex?: boolean;
        noFollow?: boolean;
    };
    meta?: {
        canonicalUrl?: string;
        seoDescription?: string;
        noIndex?: boolean;
        noFollow?: boolean;
        seoTitle?: string;
        viewport?: string;
    };
    pageElements?: PageElement[];
    pageReferenceDescription?: string;
    pageReferenceImage?: ImageMedia;
    pageReferenceTitle?: string;
    pageTags?: string;
    statusCode?: number;
    type: string;
    /**
     * @deprecated
     * Deprecated - use productCategoryIds instead
     */
    productCategoryId?: string;
    productCategoryIds?: string[];
};

export interface PageElement {
    id?: string;
    type?: string;
}

export interface PageReference {
    type: 'blockPageReference';
    callToAction?: {
        title: string;
        url: string;
    };
    pageReferenceDescription?: string;
    pageReferenceImage?: ImageMedia;
    pageReferenceTitle?: string;
}

export type Pages =
    | P20FrontPage
    | P140NotFoundPage
    | P150ErrorPage
    | P60ModulePage
    | P70ProductDetailsPageTemplate
    | P80SearchResultsPage
    | P410StaticNotFoundPage
    | P401UnauthorizedPage
    | P90FavoritePageTemplate
    | P100RecommendationsFlowPageTemplate
    | (P20FrontPage &
          P140NotFoundPage &
          P150ErrorPage &
          P60ModulePage &
          P70ProductDetailsPageTemplate &
          P80SearchResultsPage &
          P410StaticNotFoundPage &
          P401UnauthorizedPage &
          P90FavoritePageTemplate);

export type PersonalizedPage = Globalizable & {
    id: string;
    pageElements: PageElement[];
    type: string;
};

export type MeasurementSystem = 'imperial' | 'metric';

export enum KProductCardType {
    REGULAR = 'regular',
    TEXT_CTA = 'text_CTA',
    TEXT_IMAGE_CTA = 'text_image_CTA',
}
export type KProductCardVariantColor = {
    color: string;
    title: string;
    id: string;
};
export interface KProductCardModel {
    type: KProductCardType;
    imageUrl?: string;
    title: string;
    subtitle?: string;
    buttonText?: string;
    price?: string;
    productId?: string;
    url?: string;
    tags?: string[];
    colors?: KProductCardVariantColor[];
    ageGroup?: string;
    userCapacity?: string;
    spaceRequired?: React.ReactNode;
    onNavigate?: () => unknown;
    hoverImageUrl?: string;
    hoverImageQuality?: number;
    smallCard?: boolean;
}

export interface PromotedNavigationNode {
    image: ImageMedia;
    link: Link;
    text?: string;
}

export interface Redirect {
    destination: string;
    permanent: boolean;
    type: 'redirect';
    forwardQueryString?: boolean;
}

export type RichText = string;

export interface Search {
    id?: string;
}

export interface StaticLinks {
    basketPage?: Link;
    checkoutPage?: Link;
    reciptPage?: Link;
    notFoundPage?: Link;
    errorPage?: Link;
    searchPage?: Link;
    productPage?: Link;
    termsAndConditionsPage?: Link;
    gdprPage?: Link;
    myKompanLoginPage?: Link;
    myKompanLoginRedirectPage?: Link;
    myKompanSalesDocumentPage?: Link;
    myKompanSalesDocumentProductPage?: Link;
    myKompanFeaturePlannerPage?: Link;
    myKompanDashboardPage?: Link;
    myKompanProfilePage?: Link;
    myKompanMySpacesPage?: Link;
    frontPage?: Link;
    favoritePage?: Link;
    sharedFavoritePage?: Link;
}

export interface Usp {
    uspList?: UspItem[];
}

export interface SiteSettings {
    googleTagManagerId?: string;
    googleMapsApiKey: string;
}

export interface Splash {
    text?: string;
}

export interface StockStatus {
    quantityAvailable: number;
}

export interface Totals {
    handlingTotal: number;
    shippingTotal: number;
    subTotal: number;
    taxTotal: number;
    total: number;
}

export interface UspItem {
    headline?: string;
    text?: string;
    image?: ImageMedia;
    link?: Link;
}

export interface ValidatedBasketViewModel {
    culture?: string;
    id?: string;
    lineItems?: LineItem[];
    market?: string;

    /** @format double */
    quantitySum?: number;
    success?: boolean;
    totals?: Totals;
    type?: string;
    validationMessages?: string[];
}

export type Variation = Globalizable & {
    image?: ImageMedia;
    name: string;
    sku: string;
    type: string;
    url?: string;
};

export type VariationDetails = Variation & {
    discountedPrice?: Money;
    images?: ImageMedia[];
    price: Money;
    stockStatus: StockStatus;
};

export type VerticalAlignment = 'bottom' | 'center' | 'top';

export interface VideoMedia {
    /** Should the video start on load */
    autoPlay: boolean;

    /** Should the video loop */
    loop: boolean;
    src: string;

    image?: ImageMedia;
}

export interface BasketListParams {
    culture: string;
    market: string;
}

export interface BasketLineCreateParams {
    culture: string;
    market: string;

    /** @format double */
    quantity: number;
    sku: string;
}

export interface BasketLineDeleteParams {
    culture: string;
    market: string;
    sku: string;
}

export interface BasketLineUpdateParams {
    culture: string;
    market: string;

    /** @format double */
    quantity: number;
    sku: string;
}

export interface ContentFrameListParams {
    url: string;
}

export interface ContentPageListParams {
    url: string;
}

export interface ContentTranslationsListParams {
    culture: string;
}

export type ModuleQueryClientHydratorFunctionParams = {
    context: GetServerSidePropsContext;
    pageElement: PageElement;
    index: number;
    locale: string;
    pageId: string;
    frame?: Frame;
    queryClient: QueryClient;
};

export type ModuleQueryClientHydratorFunction = (
    params: ModuleQueryClientHydratorFunctionParams,
) => Promise<void>;
