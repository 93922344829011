import { Link, Text, TextVariants, Image } from '$shared/components';
import Script from 'next/script';
import parse, {
    attributesToProps,
    DOMNode,
    domToReact,
    Element,
    HTMLReactParserOptions,
} from 'html-react-parser';
import React, { useMemo } from 'react';
import { StyledTable } from '../styled';
import NextLink from 'next/link';

type RegularTextTags = (typeof regularTextTags)[number];
const regularTextTags = [
    'p',
    'b',
    'strong',
    'i',
    'em',
    'u',
    'blockquote',
    'code',
    'pre',
    'li',
] as const;

type HeadingTags = (typeof headingTags)[number];
const headingTags = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'] as const;

const getHeadlineVariant = (name: HeadingTags): TextVariants => {
    switch (name) {
        case 'h1':
            return 'display3';
        case 'h2':
            return 'display3';
        case 'h3':
            return 'display4';
        case 'h4':
            return 'display5';
        default:
            return 'display5';
    }
};

const options: HTMLReactParserOptions = {
    /**
     * Required to prevent warning about whitespace in tables - if tables are used.
     * Might removed intended whitespace.
     *
     * @see https://github.com/remarkablemark/html-react-parser#trim
     */
    trim: true,

    /**
     * Replace HTML tags with react components
     * @see https://github.com/remarkablemark/html-react-parser#replace-element-attributes
     */
    replace: (domNode) => {
        const { attribs, children, name } = domNode as Element;
        const props = attributesToProps(attribs || {});

        if (!attribs) {
            return;
        }

        if (regularTextTags.includes(name as RegularTextTags)) {
            const tag = name as RegularTextTags;
            return (
                <Text {...props} as={tag}>
                    {domToReact(children as DOMNode[], options)}
                </Text>
            );
        }

        if (headingTags.includes(name as HeadingTags)) {
            const variant = getHeadlineVariant(name as HeadingTags);
            return (
                <Text
                    {...props}
                    as={name as HeadingTags}
                    variant={variant}
                    children={domToReact(children as DOMNode[], options)}
                />
            );
        }

        if (name === 'a') {
            return (
                <NextLink href={`${props.href}`} passHref legacyBehavior>
                    <Link {...props}>{domToReact(children as DOMNode[], options)}</Link>
                </NextLink>
            );
        }

        if (name === 'img') {
            const API_URL = process.env.NEXT_PUBLIC_UMBRACO_URL;
            const isRelative = props.src.toString().startsWith('/');
            const src = isRelative ? `${API_URL}${props.src}` : props.src;

            return (
                <Image
                    src={src.toString()}
                    alt={props.alt.toString()}
                    width={Number(props.width)}
                    height={Number(props.height)}
                    layout="responsive"
                />
            );
        }

        if (name === 'table') {
            return <StyledTable {...props} children={domToReact(children as DOMNode[], options)} />;
        }

        if (name === 'script') {
            return <Script {...props} children={domToReact(children as DOMNode[], options)} />;
        }
    },
};

export const useRawHtml = (html: string) => {
    return useMemo(() => parse(html, options), [html]);
};
